import { Language } from 'types/language'
import { FaqQuestion } from 'types/faq'
import { ButtonProps } from '@boltenergy-be/design-system/dist/components/button/types'
import { BlocksContent } from '@strapi/blocks-react-renderer'

// TYPES

export type WithId<T> = T & { id: number }

export type UnknownObject = { [key: string]: unknown }

// RAW UNMAPPED STRAPI RESPONSE STRUCTURE/TYPES

export type RawStrapiType<T> = WithId<{
  attributes: T & {
    createdAt: string // ISO
    updatedAt: string // ISO
    publishedAt: string // ISO
    locale: string
  }
}>

export type RawStrapiImageModel = RawStrapiTypeInsideData<ImageModel>

export type RawStrapiList<T> = {
  data: RawStrapiType<T>[]
}

export type RawStrapiTypeInsideData<T> = {
  data: RawStrapiType<T>
}

export type RawFaqCategoryRelation = RawStrapiTypeInsideData<{
  articles: RawStrapiList<Omit<FaqQuestion, 'categories' | 'id'>>
}> | null

export type RawEncyclopediaArticlesRelation = RawStrapiList<{
  title: string
  description: string
  slug: string
  thumbnail: RawStrapiImageModel
  subCategory: RawStrapiTypeInsideData<{
    slug: string
    category: RawStrapiTypeInsideData<{ slug: string }>
  }>
}> | null

// CMS COMPONENT MODELS

export type StrapiTextAndContentComponent = {
  id: number
  description: BlocksContent
  title: string
}

export type CollectionTypeProps = {
  id: number
  locale: Language
  publishedAt: Date | string
  updatedAt: Date | string
}

export type CommonArticleProps = {
  author: Author
  contentBlocks: ContentBlockModel[]
  customPublishedAt?: Date | string
  description: string
  seo?: SeoModel
  slug: string
  thumbnail: ImageModel
  title: string
  type: CmsArticleTypes
} & CollectionTypeProps

export type PartiallyHighlightedTextModel = {
  beforeHighlight: string | null
  highlighted: string | null
  afterHighlight: string | null
  breakBeforeHighlight?: boolean
  breakAfterHighlight?: boolean
}

export type ButtonModel = {
  displayText: string
  URL?: string
  buttonType: ButtonProps['variant']
  hasGradient?: boolean
  inverseColor?: boolean
  hasArrow?: boolean
}

export type LinkModel = {
  name: string
  url: string
  newTab?: boolean
  external?: boolean
}

export type ListModel = {
  listItem: string
}

export type SeoModel = {
  seoTitle: string
  metaDescription: string
  seoImage?: string
}

type JSONValue = string | number | boolean | null | JSONObject | JSONArray

type JSONObject = { [key: string]: JSONValue }

type JSONArray = JSONValue[]

type MetaSocial = {
  socialNetwork: 'facebook' | 'twitter'
  title: string
  description: string
  image: RawStrapiImageModel
}

// Changed CMS from custom SEO to native Strapi SEO plugin
export type NewSeoModel = WithId<{
  metaTitle: string
  metaDescription: string
  metaImage?: RawStrapiImageModel
  keywords?: string
  metaRobots?: string
  structuredData?: JSONValue
  metaViewport?: string
  canonicalURL?: string
  metaSocial: MetaSocial[]
}>

type VideoModel = {
  url: string
  title: string
  autoplay: boolean
  loop: boolean
}

export type ContentBlockModel = {
  id: number
  author?: string
  type?: ContentBlocks
  body?: string
  image?: ImageModel
  quote?: string
  url?: string
  title?: string
  information?: string
  autoplay?: boolean
  loop?: boolean
}

export type ProjectSectionModel = {
  // common
  id: number
  type?: ProjectSections
  title?: string | PartiallyHighlightedTextModel
  description?: string
  backgroundColor?: CmsBackgroundColors
  layout?: CmsAlignments
  extraText?: string

  // image section
  mirrored?: boolean
  images?: {
    id: number
    showCaption?: boolean
    customCaption?: string
    image: RawStrapiImageModel
  }[]
  isLarge?: boolean
  imageStyle?: ImageStyles

  // parallax quote
  backgroundImage?: ImageModel
  quote?: string
  author?: string

  // video section
  videos?: VideoModel[]

  // partners section
  partners: PartnerModel[]

  // number section
  facts?: {
    label: string
    amount: number
  }[]
  subscript?: string

  // external card section
  cardThumbnail?: ImageModel
  cardTitle?: string
  cardDescription?: string
  button?: ButtonModel
}

export type ProjectFooterModel = {
  id: number
  type: ProjectFooters
  title: string
  buttonText?: string
  description?: string
}

type PartnerModel = {
  id: number
  name: string
  link: string
  logo: ImageModel
}

export type ImageModel = {
  url: string
  width: number
  height: number
  alternativeText: string
  caption?: string
  formats?: {
    thumbnail?: {
      url: string
      width: number
      height: number
    }
  }
  provider_metadata: {
    public_id: string
    resource_type: string
  }
}

export type LocalizationModel = {
  slug: string
  locale: Language
}

export type Author = {
  id: number
  avatar?: ImageModel
  firstName: string
  lastName?: string
  personalizedFooter?: {
    title: string
    description: string
  }
}

export type Testimonial = {
  name: string
  text: string
  image: RawStrapiImageModel
  caption: {
    author: string
    extra: string
    url: string
    logo: RawStrapiImageModel
  }
}

export type Testimonials = RawStrapiList<Testimonial>

export type CmsResponse<T> = {
  error?: boolean
  message?: string
  data?: T
}

// ENUMS

export enum ContentBlocks {
  RICH_TEXT = 'content-blocks.rich-text',
  IMAGE = 'content-blocks.image',
  QUOTE = 'content-blocks.quote',
  GOOD_TO_KNOW = 'content-blocks.good-to-know',
  VIMEO = 'content-blocks.vimeo'
}

export enum ProjectSections {
  VIDEO_SECTION = 'projects.video-section',
  IMAGE_SECTION = 'projects.image-section',
  PARALLAX_QUOTE = 'projects.parallax-quote',
  PARTNERS_SECTION = 'projects.partners',
  NUMBER_SECTION = 'projects.number-section',
  EXTERNAL_CARD_SECTION = 'projects.external-card-section'
}

export enum ProjectFooters {
  SOCIAL_CTA = 'projects.social-cta',
  SIMULATION_CTA = 'projects.simulation-cta',
  NEWS_CTA = 'projects.news-cta'
}

export enum CmsBackgroundColors {
  WHITE = 'white',
  LIGHT_BLUE = 'light blue'
}

export enum CmsAlignments {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export enum ImageStyles {
  REGULAR = 'regular',
  CARD = 'card'
}

export enum CmsArticleTypes {
  ENCYCLOPEDIA = 'encyclopedia',
  BLOG_INTERNAL = 'blogInternal',
  BLOG_EXTERNAL = 'blogExternal'
}

export enum CmsPages {
  ENCYCLO_CATEGORY = 'encyclopedia-category',
  ENCYCLO_SUB_CATEGORY = 'encyclopedia-sub-category',
  ENCYCLO_ARTICLE = 'encyclopedia-article',
  BLOG_ARTICLE = 'blog-article',
  FAQ_ARTICLE = 'faq-article',
  FAQ_CATEGORY = 'faq-category',
  TEAM_MEMBER = 'team-member',
  JOB_OFFER = 'job-offer',
  PROJECT = 'project',
  PRICING = 'pricing-v2',
  PRODUCT_LANDINGSPAGE = 'product-landingspage',
  ENERGY_GARDEN = 'energy-garden'
}
