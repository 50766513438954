import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'next-i18next'
import { SimulationType } from 'store/marketing/types'
import { getCapacityState, getProducerLocation, isReserved as getIsReserved } from 'utils/producers'
import { routes } from 'utils/routes'
import Price from './Price/Price'
import styles from './ProducerCard.module.scss'
import { ProducerCardProps } from './types'
import ProducerLocation from 'components/producers/producer-location/ProducerLocation'
import ProducerCta from 'components/producers/producer-cta/ProducerCta'
import { useStoreSelector } from 'hooks/store'
import { CldImage } from 'next-cloudinary'
import { ProgressIndicator, Button } from '@boltenergy-be/design-system'
import Link from 'components/link/Link'

const ProducerCard = ({
  producer,
  onMouseEnter,
  onMouseLeave,
  isActive,
  showDescription,
  showCTA,
  simulationSalesOffice
}: ProducerCardProps) => {
  // REDUX STORE
  const { inputs, flows } = useStoreSelector((store) => store.customer)
  const { didSimulation, isLoading } = flows.simulation

  // Next Router
  const router = useRouter()
  const { locale } = router

  // i18n
  const { t } = useTranslation(['producer', 'producers'])

  // Constants
  const capacityState = useMemo(
    () => getCapacityState(producer, inputs.simulation, didSimulation),
    [didSimulation, inputs.simulation, producer]
  )
  const isReserved = useMemo<boolean>(() => getIsReserved(producer), [producer])

  /**
   * Returns the state based on the capacity state & popularity
   *
   * @returns HTMLElement|null
   */
  const getState = () => {
    if (isReserved) {
      return <Button className={styles.reserved}>{t('reserved', 'Gereserveerd')}</Button>
    }

    if (capacityState.soldOut) {
      return (
        <Button className={styles['sold-out']} variant="tertiary">
          {t('soldOut', 'Uitverkocht')}
        </Button>
      )
    }

    if (capacityState.almostSoldOut) {
      return (
        <Button className={styles['sold-out']} variant="tertiary">
          {t('almostSoldOut', 'Bijna uitverkocht')}
        </Button>
      )
    }

    if (producer.isPopular[locale]) {
      return (
        <Button className={styles['popular']} variant="tertiary">
          {t('isPopular', 'Populair')}
        </Button>
      )
    }

    return null
  }

  return (
    <div
      role="presentation"
      className={classNames(styles['producer-card'], {
        [styles['is-active']]: isActive || producer.isPopular[locale]
      })}
      onClick={() => router.push(routes(locale, producer.parentSlug).producerInfo)}
      onMouseEnter={() => onMouseEnter && onMouseEnter(producer)}
      onMouseLeave={() => onMouseLeave && onMouseLeave()}
    >
      <div className={styles.inner}>
        <div className={styles.photo}>
          {producer.images.profile?.url && (
            <CldImage
              src={producer.images.profile.url}
              alt={producer.name[locale]}
              fill
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 600px) 580px, (max-width: 900px) 400px, (max-width: 1280px) 360px, 280px"
            />
          )}
          {getState()}
        </div>
        <div className={styles['producer-content']}>
          {inputs.simulation.chosenSimulationType === SimulationType.PRICING_SIMULATION && (didSimulation || isLoading) && <Price />}

          <div className={styles['producer-location-container']}>
            <ProducerLocation energyType={producer.energyType} location={getProducerLocation(locale, producer?.location)} />
          </div>

          <h3 className={classNames(styles.name, { 'mb-200': showDescription, 'mb-400': !showDescription })}>{producer.name[locale]}</h3>
          {showDescription && (
            <>
              <div className={styles.description}>
                <Dotdotdot clamp={3}>{producer.description[locale]}</Dotdotdot>
              </div>

              <Link
                href={routes(locale, producer.parentSlug).producerInfo}
                className={styles['read-more']}
                variant="secondary"
                size="small"
              >
                {t('readMore', 'Meer info')}
              </Link>
            </>
          )}
          <ProgressIndicator
            label={t('alreadyOrdered', 'Reeds besteld')}
            className={classNames({ 'mb-400': showCTA })}
            progress={capacityState.usedEnergy}
          />
          {showCTA && <ProducerCta {...{ capacityState, isReserved, simulationSalesOffice }} producerSlug={producer.slug} />}
        </div>
      </div>
    </div>
  )
}

ProducerCard.defaultProps = {
  showCTA: false,
  showDescription: true
}

export default ProducerCard
